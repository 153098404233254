<!-- ImageViewer
  查看大图 (图片列表)

  props:
    srcList: 要显示的图片 URL 列表
    index: 默认显示第几张图片 (从 0 开始)
  
  event:
    close: 关闭

-->

<template>
	<div class="image-viewer">
		<ElImageViewer :urlList="srcList" :onClose="close" :initialIndex="index" />
	</div>
</template>

<script>
	import ElImageViewer from 'element-ui/packages/image/src/image-viewer.vue'

	export default {
		name: "ImageViewer",
		components: {
			ElImageViewer
		},
		props: {
			srcList: {
				type: Array,
				required: true,
			},
			index: {
				type: Number,
				required: true,
			}
		},
		methods: {
			close() {
				this.$emit('close');
			}
		}
	}
</script>

<style lang="scss" scoped>

</style>
